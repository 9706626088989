.imageWrapper {
  width: 100%;
  height: 100%;
  min-height: var(--facultyCoverHeight);
  background-size: cover;
  background-position: center;
}

.card{
  border-radius: 0 !important;
}

/* Same behaviour as Row */
.linkWrapper{
  display: flex;
  flex-grow: 2;
  flex-wrap: wrap;
}

.contentWrapper{
  padding-left: 100px;
}

.contentWrapper h2,
.contentWrapper p{
  margin: 0;
}

.description {
  color: var(--grey-custom);
}

.statsRoot{
  display: flex;
  justify-content: flex-start;
}

.statSingle{
  margin-right: 50px;
}

.statsIcon{
  color: #25248E;;
}

.statsText{
  color: var(--grey-custom);
}

.statsValue{
  color: black;
}

.teacherName{
  font-size: 14px;
  font-weight: 900;
  color: #424B5A;
}

.teacherPosition{
  font-size: 14px;
  color: var(--grey-custom);
}

.name{
  padding-top: 55px;
}

.category{
  padding-top: 20px;
}

.putMeDown{
  flex-grow: 1;
  margin-top: auto;
}

.teacherWrapper{
  padding-bottom: 12px;
}

.stats{
  margin-top: 28px;
}

.moreInfo{
  text-align: end;
}

@media (max-width: 991px) { 
  .teacherWrapper{
    padding-bottom: 25px;
    padding-top: 50px;
  }
  
  .name{
    padding-top: 30px;
    text-align: center;
  }

  .category{
    padding-top: 20px;
  }

  .contentWrapper{
    padding-left: 0;
  }

  .description{
    padding-top: 10px;
    text-align: center;
  }

  .category{
    text-align: center;
  }

  .logo{
    width: 70px;
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
  }

  .imageWrapper {
    min-height: 200px;
    height: 200px;
  }
}