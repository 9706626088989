.closeActionArrow, 
.openActionArrow, 
.closeActionPlusMinus, 
.openActionPlusMinus{
  color: var(--grey-custom);
  width: 25px;
}

.base{
  flex-grow: 2;
  text-align: end;
  margin-left: 10px;
}

.closeActionArrow, 
.openActionArrow{
  composes: base;
}

.closeActionPlusMinus, 
.openActionPlusMinus{
  composes: base;
}

.collapseImg{
  width: 13px;
  height: 13px;
  background-image: url(../../../assets/images/Chevron_down@2x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.collapseImg.open{
  transform: rotate(180deg);
}