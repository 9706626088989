.slick-slider{
  --arrow-position-default: -25px;
  --arrow-position-xxs-step: 13px;
}

.liveSliderWrap .slick-track{
  margin: 0;
}

.liveSliderWrap .slick-list{
  padding-bottom: 8px;
}

@media (max-width: 320px) {  
  .xxsGridModifier.col-10{
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .xxsGridModifier.offset-1{
    margin-left: 0;
  }

  .liveSliderWrap .slick-next{
    right: calc(var(--arrow-position-default) + var(--arrow-position-xxs-step));
  }

  .liveSliderWrap .slick-prev{
    left: calc(var(--arrow-position-default) + var(--arrow-position-xxs-step));
  }
}