.root{
  background-color: #FFF;
  padding-top: 60px;
  padding-bottom: 30px;
}

.textCol{

}

.icon{
  color: #25248E;
}

.gray{
  color: var(--grey-custom);
}
