.label {
  margin-left: 0.5rem;
  margin-right: 0.25rem;
}

.statRoot p{
  color: white;
}

.statBox {
  margin-bottom: 0.75rem;
}

.badgeCourseRoot{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.badgeCourseStyle{
  font-size: 14px;
}

.badgeCourseIcons{
  composes: badgeCourseStyle;
  color: #25248E;
}

.badgeCourseText{
  composes: badgeCourseStyle;
  color: #424B5A;
}

.badgeCourseValue{
  composes: badgeCourseStyle;
  color: #424B5A;
  font-weight: 900;
}

.badgeCourseItem{
  margin-right: 15px;
}