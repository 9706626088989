.playerRoot{
  /* -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms; */
  height: 100%;
}

.opacityZero{
  /* opacity: 0;
  visibility: hidden; */
  display: none;
}

.opacityOne{
  /* opacity: 1;
  visibility: visible;
  display: initial */
}