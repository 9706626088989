.seriesSelected::before{
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;
  border: 10px solid #172E88;
  z-index: 999;
}

.sliderWrapper{
  
}

.intro{
  padding-bottom: 0;
}

.pillsSliderWrapper{
  background-color: #EAEAEA;
}

.pillsSliderWrapper h4{
  padding-top: 60px;
  margin-bottom: 40px
}

.pillsSliderWrapper.facultyNoAccess h4{
  margin-bottom: 0;
}

.pillDescription{
  color: var(--grey-custom);
}

.serieSliderTextContainer{
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;
  color: white;
  --cta-height: 50px;
}

.sliderSerieItemGradient{
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%; 
  height: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 80%);
}

.seriesSliderWrapper{
  margin-top: auto;
  padding-left: 50px !important;
  padding-top: 25px;
  margin-bottom: calc(var(--cta-height) * -1);
  transition: margin 0.3s ease;
}

.seriesSliderText{
  position: relative;
}

.pillWrapper{
  margin-bottom: 40px;
  min-height: 360px;
  cursor: pointer;
}

.pillWrapper.noAccess{
  min-height: 300px;
}

.pillBuyCtaWrap{
  padding-top: 25px;
  text-align: center;
}

.facultyPrice{
  padding: 0;
  text-align: center;
}

.pillWrapperNoAccess{
  cursor: default;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
}

.ctaWrapper{
  position: relative;
  height: var(--cta-height);  
}

.ctaWrapper p{
  margin: 0;
}

.serieSliderTextContainer.hasCta:hover .seriesSliderWrapper{
  margin-bottom: 0;
}

.pillTime{
  color: var(--grey-custom);
}

.pillTitle{
  padding-top: 20px;
  min-height: 110px;
  justify-content: space-between;
}

.pillProgress{
  width: 20%;
  text-align: right;
  color: green;
}

.extraIntroDescription{
  width: 70%;
  color: var(--grey-custom);
}

.pillDescriptionWrap{
  min-height: 75px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.pillDescriptionWrap p{
  margin: 0;
}

.pillDescriptionWrap .buttonWrapper{
  margin: auto auto 20px auto;
}

.readMore{
  padding: 10px 15px !important;
}

.pillModalCol{
  text-align: center;
  height: 0;
  position: relative;
  overflow: hidden;
}

.pillModalVideo{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pillModalDescription{
  margin-top: 30px;
}

.vimeoPreview{
  margin-top: 15px;
}

.selectedSerieTitle{
  text-align: center;
  margin-bottom: 20px !important;
}

.showAll{
  text-align: center;
  padding-bottom: 40px;;
}

.showAllButton{
  background-color: #25248E !important;
  border-color: #25248E !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.sliderSerieBackground{
  height: 300px;
}

.buyFromLanding{
  text-align: center;
  padding-bottom: 20px;
}

@media (max-width: 1199px) {
  .pillDescriptionWrap{
    min-height: 95px;
  }
}

@media (max-width: 991px) {
  .pillDescriptionWrap{
    min-height: 120px;
  }
  
  .seriesSliderWrapper{
    padding-left: 20px !important;
    margin-bottom: 0;
  }
  .pillTitle{
    min-height: 110px;
  }
  .sliderSerieBackground{
    height: 230px;
  }
}

@media (max-width: 767px) { 
  .pillDescriptionWrap{
    min-height: 95px;
  }

  .pillTitle{
    min-height: 85px;
  }

  .sliderSerieBackground{
    height: 300px;
  }
}

@media (max-width: 575px) {
  .sliderWrapper{
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 410px) { 
  .pillDescriptionWrap{
    min-height: 120px;
  }
  .pillTitle{
    min-height: 110px;
  }
  .sliderSerieBackground{
    height: 220px;
  }
}

@media (max-width: 330px) { 
  .pillDescriptionWrap{
    min-height: 145px;
  }
  .pillTitle{
    min-height: 110px;
  }
  .sliderSerieBackground{
    height: 170px;
  }
}

