.intro{
  padding-top: 74px;
  padding-bottom: 150px;
}

.introTitle{
  margin: 0 0 18px 0;
}

.introDescription{
  width: 35%;
  margin: auto;
}

.rootRow{
  margin-top: 68px;
}

.firstEventInfo{
  margin-top: 65px;
}

.firstEventInfo h4, .firstEventInfo p{
  margin: 0;
}

.firstEventInfo .startAt{
  color: var(--red-custom);
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.4px;
  margin-bottom: 10px;
}

.firstEventInfo .title{
  margin-bottom: 20px;
}

.firstEventInfo .description{
  color: var(--grey-custom);
}

.videoWrap{
  height: 0;
  position: relative;
  overflow: hidden;
}

.absoluteFiller{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blur{
  filter: blur(8px) brightness(0.3);
}

.loginBox{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/images/blurred-image-login.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  color: white;
}

.ctaBox{
  margin-bottom: 70px;
  text-align: center;
}

.teachersWrap{
  margin-top: 15px;
}

.teacher{
  margin-bottom: 20px;
}

.deskEventsWrap{
  margin-top: 15px;
}

.mobileEventsWrap{
  margin-top: 35px;
}

.teacherName, 
.positionName{
  font-family: 'Lato';
  font-size: 14px;  
  margin-left: -20px;
}

.teacherName{
  font-weight: 600;
}

.nextLiveDesk{
  width: 60%;
  padding-top: 110px;
}

.nextLiveDesk h5{
  margin-bottom: 35px;
}

.viewAll{
  background-color: #25248E !important;
  border-color: #25248E !important;
  width: 160px;
  height: 40px;
  border-radius: 20px !important;
  font-family: 'Lato';
  font-size: 12px !important;
  line-height: 28px !important;
}

.sliderWrap{
  margin-bottom: 20px;
}

.sliderCardWrap{
  padding-left: 15px;
  padding-right: 15px;
  max-width: 300px;
  margin: auto;
}


@media (max-width: 768px) {  
  .intro{
    padding-bottom: 50px;
  }

  .introDescription{
    width: 100%;
  }

  .rootRow{
    margin-top: 26px;
  }

  .firstEventInfo{
    margin-top: 34px;
  }

  .ctaBox{
    margin-bottom: 10px;
  }

  .loginButton{
    font-size: 13px !important;
    padding: 10px 25px !important;
  }

  .firstEventInfo .startAt{
    margin: 0;
  }

  .teacherName,
  .positionName{
    margin-left: -10px;
  }
}

@media (max-width: 320px) {  
  .teacherName,
  .positionName{
    margin-left: 0;
  }
}