.root{
  min-height: 400px;
  padding-bottom: 20px;
}

.linkWrapper{
  display: flex;
  flex-direction: column;
}

.imageContainer{
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-right-radius: .25rem;
  border-top-left-radius: .25rem;
  margin-left: -15px;
  margin-right: -15px;
}

.teacherName{
  font-size: 14px;
  color: #424B5A;
}

.teacherPosition{
  font-size: 14px;
  color: #A1AeB7;
}

.teacherRoot{
  min-height: 70px;
}

.courseNameWrapper{
  margin-top: 17px;
  min-height: 58px;
}

.progressWrapper{
  width: 40px; 
  min-width: 40px; 
  margin: auto; 
}

.progressColumn{
  /* padding: 0;  */
  display: flex; 
  justify-content: flex-end;
}

.teacherWrapper{
  flex-grow: 0 !important;
}

.courseName{
  font-weight: 900;
  letter-spacing: 0.2px;
}

.ctaRoot{
  display: flex;
  height: 40px;
  margin-top: 7px;
  background-color: #25248E;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.ctaRoot p{
  margin: 0;
  text-align: center;
  margin: auto;
}
