.slick-list::before{
  /* content: "";
  position: absolute;
  top: 0; 
  left: 90%;
  width: 10%; 
  height: 100%;
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
  z-index: 2; */
}

.slick-next:not(.slick-disabled), 
.slick-prev:not(.slick-disabled){
  
}

.slick-next:before, 
.slick-prev:before{
  content: none;
}

.slick-next:focus, 
.slick-next:hover, 
.slick-prev:focus, 
.slick-prev:hover{
  color: initial;
  background: initial;
}

.slick-arrow.slick-disabled{
  display: none !important;
}

