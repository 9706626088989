.root{
  min-height: 365px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.titleWrap{
  min-height: 80px;
  height: 80px;
}

.titleWrap p{
  font-weight: 900;
}

.day{
  font-weight: 900;
}

.more{
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  margin: auto;
  background-color: #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.more p{
  margin: 0;
  padding: 0;
  color: #25248E;
  font-weight: 900;
  font-family: 'PT Serif';
  font-size: 16px;
}

.teacher{
  margin-bottom: 10px;
}

.teacherName{
  font-family: 'Lato';
  font-size: 14px;
  margin-left: 10px;
}