.box {
  width: 225px;
  height: 225px;
  margin: 0.5rem;
}

.wrapper {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.contact {
  width: 100%;
  height: 100%;
  background-color: #25248E;
  color: var(--grey-custom);
  text-align: center;
  font-size: 14px;
}

.name {
	font-family: "PT Serif";
	color: white;
	font-size: 21px;
	font-weight: bold;
}
