.maxWidth{
  max-width: 210px;
}

.customFont{
  font-size: 14px !important;
  color: var(--grey-custom) !important;
}

.logoWrapper{
  width: 101px;
  height: 63px;
  margin: auto;
  margin-top: -20px;
  margin-bottom: 30px;
}

.signinTitle{
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.socialBox{
  composes: maxWidth;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.175) !important;
  cursor: pointer;
}

.socialLogo{
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.socialSignInText{
  composes: customFont;
  font-weight: bold;
  color: #757575 !important;
}

.modalText{
  composes: customFont;
  text-align: center;
}

.customInput::placeholder, .customInput::-webkit-input-placeholder{
  font-size: 14px !important;
  color: var(--grey-custom) !important;
  text-align: center;
}

.formGroupWrapper{
  text-align: center;
}

.formError{
  min-height: 35px;
  padding-top: 5px;
  margin-bottom: 0;
}

.customFormGroup{
  composes: maxWidth;
  margin-left: auto;
  margin-right: auto;
}

.privacyWrapper{
  text-align: center;
}

.privacyWrapper p{
  color: var(--grey-custom) !important;
  font-size: 12px;
}

.privacyWrapper p a{
  color: inherit;
  text-decoration: underline;
}

.buttonWrapper{
  margin-bottom: 20px;
  text-align: center;
}

.textAction{
  text-decoration: underline;
  cursor: pointer;
}

.pwrResetMsg{
  font-weight: 900;
  margin-bottom: 30px !important;
}

.errorMessage{
  composes: customFont;
  color: red !important;
  text-align: center;
  font-size: 13px !important;
  margin-bottom: 0;
}

.customButton{
  padding-left: 25px !important;
  padding-right: 25px !important;
  background-color: #25248E !important;
  border-color: #25248E !important;
}

.claim{
  margin-bottom: 40px;
}

.passwordReset,
.passwordReset p{
  margin-bottom: 0;
}

.signUpName{
  margin-bottom: 15px;
}

@media (max-width: 768px){
  .claim{
    margin-bottom: 0;
  }
  
  .logoWrapper{
    width: 80px;
    height: 50px;
    margin: auto;
    margin-top: -30px;
    margin-bottom: 20px;
  }
}