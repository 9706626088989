.lessonsResume.align-items-center{
  padding-bottom: 40px;
}

.list-group-item:first-child{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.list-group-item.courseObjective{
  
}

.list-group-item.mobileToggleItem{
  padding: 10px 8px ;
}

.list-group-item.mobileToggleItem p{
  font-size: 14px ;
  font-weight: 600 ;
}

.list-group-item.listItem{
  padding-left: 23px;
  padding-top: 26px;
  padding-bottom: 26px;
}

.list-group-item.listLessonItem{
  padding-bottom: 15px;
  margin-left: 20px;
}

.list-group-item.listLessonItem p{
  font-weight: 600;
}