.badge.custom-badge {
  margin-right: 10px;
  margin-bottom: 5px;
  font-family: 'Lato';
  padding: 6px 12px;
}

.badge.custom-badge.category-badge{
  background-color: #172E88;
  font-size: 16px;
}

.badge.custom-badge.audio-badge{
  background-color: #68EA67;
  padding: 1px 14px;
  margin: 0;
}

.badge.custom-badge.audio-badge div{
  height: 30px;
}

.badge.custom-badge.audio-badge .audio{
  font-weight: 900;
  font-size: 12px;
}

.badge.custom-badge.audio-badge .oi-microphone{
  font-size: 18px;
  margin-right: 5px;
  height: 26px;
}

.badge.custom-badge.green-badge{
  margin: 0;
  padding: 6px 17px;
}

.badge.badge-secondary.green-badge{
  background-color: #68EA67;
}

.badge.custom-badge.green-badge p{
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.4px;
}