.root{
  margin-top: 110px;
  margin-bottom: 180px;
  /* --moduleHighlight: rgba(0,0,0,0.1); */
  --moduleHighlight: var(--main-background);
  --lessonHighlight:white;
}

.root h3{
  margin-bottom: 50px;
}

.moduleRow{
  position: relative;
}

.rowDisabled{
  filter: blur(1px);
}

.lessonFirstRow{
  margin-bottom: 10px;
  border-bottom: 1px solid #A1AEB7;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.lessonFirstRow:hover{
  background-color: var(--lessonHighlight);
}

.number{
  background: linear-gradient(40.44deg, #8C12CF 0%, #3F95E9 100%);
  border-radius: 50%;
  color: white;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
}

.number h4,
.number h5{
  font-weight: 900;
  margin: 0;
}

.titleWrap{
  padding-top: 14px;
  padding-bottom: 14px;
}

.title{
  min-width: 0;
  margin-bottom: 3px;
}

.title p{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}

.desktopDescription{
  color: #A1AEB7;
}

.desktopDescription p{
  margin: 0;
}

.freeButton{
  background-color: #68EA67 !important;
  border-color: #68EA67 !important;
  padding: 6px 24px !important;
  font-weight: 900 !important;
}

.lock{
  height: 36px;
  width: 36px;
  background-image: url(../../assets/images/lock.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.moduleItemRoot{
  cursor: pointer;
  height: 100px;
  transition: background-color 0.5s ease;
}

.moduleItemRoot:hover, .moduleSelected{
  background-color: var(--moduleHighlight);
}

.onlyBorder{
  width: 100%;
  border-bottom: 1px solid grey;
}

.onlyBorder.moduleOpen{
  margin-top: 12px;
}

.lessonOverlay{
  position: absolute !important;
  height: 100%;
}

.lessonOverlay div{
  background-color: rgba(120, 120, 120, 0.3);
}

@media (max-width: 991px){
  .lessonRoot{
    margin-bottom: 10px;
  }

  .shado{
    box-shadow: 0 13px 24px 0 rgba(0,0,0,0.14);
  }

  .lessonFirstRow{ 
    margin-bottom: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-bottom: 1px solid #EEEEEE;
  }

  .mobileDescription{
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: white;
    padding-top: 12px;
    color: #A1AEB7;
  }
}

@media (max-width: 768px) {  
  .number{
    width: 30px;
    height: 30px;
  }

  .openWrapper{
    justify-content: flex-end;
  }

  .openWrapper div{
    margin-right: 15px;
  }

  .freeButton{
    line-height: 10px;
  }
}