/* How to import align-items-center class from bootstrap file?? */
.videoDuration{
  flex-shrink: 0;
  font-size: 14px;
}

.actionItem:hover{
  cursor: pointer;
  background-color: #f8f9fa;
}

.activeItem {
  background-color: rgba(37,36,142,0.09) !important;
}

@media (max-width: 768px) {
  .root{
    margin-bottom: 40px !important;
    margin-top: 30px !important;
  }
}