.btn.redButton{
  background-color: var(--red-custom);
  border-color: var(--red-custom);
  border-radius: 32px;
  font-size: 15px;
  font-family: Lato;
  font-weight: 900;
  padding: 20px 25px;
}

.btn-secondary.redButton:focus,
.btn-secondary.redButton:active,
.btn-secondary.redButton:hover{
  background-color: var(--red-custom) !important ;
  border-color: var(--red-custom) !important;
}