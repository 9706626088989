.imgWrapper{
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 40px auto 16px auto;
}

.root{
  min-height: 400px;
  padding-bottom: 20px;
}

.name, .position, .description{
  text-align: center;
}

.name h5{
  margin: 0;
}

.position p, .description p{
  color: var(--grey-custom);
}

.description p{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.social{
  display: flex;
  flex-grow: 2 !important;
  margin-bottom: 15px;
}

.socialWrapper{
  display: flex;
  justify-content: center; 
  margin-top: auto;
  width: 100%;
}

.socialWrapper div{
  margin: 0 7px 0 7px;
}

.socialWrapper div a{
  color: initial;
}

.socialWrapper div a img{
  width: 24px;
  height: 24px;
}