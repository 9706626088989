.root{
  width: 100%;
  background-color: white;
  padding-top: 70px;
}

.title{
  margin-top: 5px;
}

.description{
  color: var(--grey-custom);
}

.loadMoreWrap{
  text-align: center;
  padding: 40px 0 40px 0;
}

.loadMore{
  background-color: #25248E !important;
  border-color: #25248E !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  text-transform: uppercase;
}