.root {
  padding-bottom: 100px;
  position: relative;
  height: 100%;
  background-image: url(../../assets/images/home.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.root::before{
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; 
  height: 100%;
  background-color: #172E88;
	opacity: .9; 
}

.headerRow {
  padding-top: 100px;
}

.headerCol {
  padding-bottom: 40px;
  color: white;
  text-align: center;
  margin-top: 30px;
}

.headerCol p{
  margin: 15px 0 150px 0;
  letter-spacing: 2.1px;
}

.backBlock{
  cursor: pointer;
  display: inline-flex;
}

.backBlock p{
  color: white;
  margin: 0;
  margin-left: 10px;
  line-height: 40px;
}

.eventsDay{
  margin-top: 40px;
}

.day{

}

.day p{
  font-family: 'PT Serif';
  font-size: 40px;
  font-weight: bold;
}

.desktopSlider{
  margin-left: -30px !important;
  margin-right: -30px !important;
}

/* .sliderCardWrap{
  padding-left: 15px;
  padding-right: 15px;
  max-width: 300px;
  margin: auto;
} */

@media (max-width: 768px) {  
  .root{
    padding-bottom: 60px;
  }

  .headerRow {
    padding-top: 70px;
  } 
  
  .backBlock p{
    line-height: 35px;
  }

  .headerCol{
    margin-top: 45px;
  }

  .headerCol p{
    letter-spacing: initial;
    margin: 35px 0 0 0;
  }
}