.root{
  --dimension: 40px;
  --mobile-dimension: 35px;
  border-radius: 50%;
  min-width: var(--dimension);
  min-height: var(--dimension);
  width: var(--dimension);
  height: var(--dimension);
  margin: auto;
  background-color: #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root span{
  margin: 0;
}

@media (max-width: 768px) {  
  .root{
    min-width: var(--mobile-dimension);
    min-height: var(--mobile-dimension);
    width: var(--mobile-dimension);
    height: var(--mobile-dimension);
  }
}
