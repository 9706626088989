.root{
  flex-grow: 1;
  display: flex;
  min-height: 0;
}

.rootContainer{
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.titleRow{
  flex-shrink: 0;
}

.lessonBoxRow{
  min-height: 0;
  flex-wrap: nowrap !important;
}

.videoBoxRoot{
  padding-left: 0 !important;
}

.videoBoxSpin{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;  
}

.videoBoxDescription{
  padding-top: 30px;
  padding-bottom: 15px;
  background-color: var(--main-background); 
}

.videoBoxDescription p,
.videoBoxDescription h4,
.videoBoxDescription h5{
  margin: 0;
}

.videoBoxDescription h4{
  margin-top: 20px;
  margin-bottom: 10px;
}

.videoBoxDescription p{
  color: var(--grey-custom);
}

.videoCol{
  height: 0;
  position: relative;
  overflow: hidden;
}

.absoluteFiller{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay{
  height: 60px;
  transition-property: top;
  transition-duration: 0.5s;
}

.overlayOpen{
  top: 0;
}

.overlayClose{
  top: -100px;
}

.overlayText p{
  background-color: #2D272B;
  margin: 0;
  padding: 6px 9px;
  color: white;
  display: inline-block;
}

.overlayTeacherName{
  margin-top: 3px;
}

.overlayTeacherName p{
  padding-top: 3px;
  padding-bottom: 3px;
}

.olerlayTeacherImg{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 4px solid #2D272B;
}

.mobileLessonTitle p{
  background-color: white;
  margin: 0;
  padding: 15px;
  font-weight: 900;
}

.videoBoxWrapper{
  overflow: auto;

  /* max-height: -webkit-fill-available; */
}

.lessonsBoxWrapper{
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow: auto;
  min-height: 0;

  /* max-height: -webkit-fill-available; */
}

.materialLogo{
  width: 20px;
  height: 100%;
}

.materialsWrapper{
  margin-top: 30px;
}

.materialsWrapper a p{
  font-size: 12px;
  color: #25248E;
  text-decoration: underline;
  margin-left: 6px;
}

@media (max-width: 768px) {
  .root{
    padding-top: 0;
    background-color: #EAEAEA;
  }

  .videoBoxRoot{
    padding-left: 15 !important;
    padding-left: 15 !important;
  }

  .lessonBoxRow{
    flex-wrap: wrap !important;
  }

  .videoWrapper{
    padding-left: inherit;
    padding-right: inherit;
  }

  .videoBoxDescription{
    background-color: #EAEAEA;
    padding-top: 0;
  }

  .videoBoxSpacer{
    padding-left: 10px;
    padding-right: 10px;
  }

  .videoBoxDescription div{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
  }

  .lessonsBoxWrapper{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  
  .materialsWrapper a p{
    margin-left: 13px;
    margin-bottom: 30px;
    display: inline-block;
  }
}