.slide{
  /* margin: 10px; */
}

.itemWrapper{
  position: relative;
  cursor: pointer;
}

/* https://github.com/akiran/react-slick/issues/1378 */
.sliderWrapper > div .itemContainer{
  display: block !important;
}
