.root{
  width: 100%;
  background-color: white;
}

.top{
  /* background-image: linear-gradient(to right, #8C12CF 0%, #3F95E9 65%); */
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: white;
}

.desktopCover{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.mobileCover{
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.descriptionWrapper{
  padding-top: 70px;
}

.facultyDescription{
  display: flex;
  align-items: center
}

.facultyLogo{
  width: 40px;
  height: 45px;
}

.facultyName p{
  margin: 0 0 0 10px;
}

.nextStep p:nth-child(1),
.nextStep p:nth-child(2){
  margin-bottom: 0;
}

.nextStep p:nth-child(2){
  margin-bottom: 0;
}

.teachersWrapper{
  margin-bottom: 13px;
}

.teachersWrapper h3{
  margin-bottom: 30px;
}

.teacherWrapper{
  margin-bottom: 12px;
}

.teacherProfile{
  margin-top: initial;
  margin-bottom: initial;
}

.teacherPreviewWrap{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.badgesWrapper{
  margin-bottom: 55px;
}

.courseTitle{
  margin-top: 5px;
  margin-bottom: 22px;
}

.teacherName{
  font-weight: 900;
}

.facultyPrice{
  width: 310px;
  text-align: center;
}

.ctaCourseButton{
  display: inline-block;
}

.ctaCourseButton p{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.ctaBuyCourseButton,
.loggedCourseButton{

}

.buyFromLanding{
  color:green;
}

@media (max-width: 768px) {  
  .descriptionWrapper{
    padding-top: 25px;
  }

  .top{
    padding-top: 0;
    padding-bottom: 0;
  }

  .ctaWrapper{
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .badgesWrapper{
    margin-bottom: 32px;
  }

  .teachersWrapper{
    margin-bottom: 22px;
  }

  .teacherPosition{
    font-size: 12px;
  }

  .teacherWrapper{
    margin-bottom: 12px;
  }

  .facultyPrice{
    padding: 0;
  }
}