.fluid{
  width: 100%;
}

.root {

}

.intro {
  text-align: center;
}

.zig {
  composes: root;
  background-color: #F6F6F6;
}

.zag {
  composes: root;
  background-color: #FFFFFF;
}
