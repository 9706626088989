.btn.customActionButton.actionDefault{
  background-color: var(--red-custom);
  border-color: var(--red-custom);
  color: white;
}

.btn.customActionButton.actionCta{
  background-color: var(--red-custom);
  border-color: var(--red-custom);
  color: white;
}