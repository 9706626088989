.container{
  padding: 20px;
}

.row{
  padding-bottom: 20px;
}

.contentCol{
  background-color: white;
}

.desktopRoot{
  margin-top: 25px;
  margin-left: 25px;
  display: flex;
  flex-wrap: wrap;

}

.contentCol p,
.contentCol h4{
  margin: 0;
}

.contentWrapper{
  padding-left: 30px;
}

.objective .contentWrapper{
  padding-top: 35px;
}

.lessonLength .contentWrapper{
  padding-top: 20px;
}

.description .contentWrapper{
  padding-top: 15px;
  padding-bottom: 50px;
}

.lessonLength .contentWrapper,
.description .contentWrapper{
  color: var(--grey-custom);
}

.description .contentWrapper{
  min-height: 190px;
}

.startCourse .contentWrapper{
  padding-bottom: 46px;
}

@media (max-width: 768px) {
  .mobileRoot{
    margin-bottom: 30px;
  }

  .contentCol{
    background-color: initial;
  } 

  .contentWrapper{
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
  }

  .lessonLength .contentWrapper{
    padding-top: 0;
  }

  .description .contentWrapper{
    color: initial;
  }
  
  .description .contentWrapper{
    min-height: initial;
  }

  .startCourse{
    text-align: center;
  }
}