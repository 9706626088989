.root{
  padding-bottom: 30px;
  padding-top: 35px;
  background-color: white;
}

.facultyName{
  color: var(--grey-custom);
  margin-bottom: 0;
}

.facultyLogo{
  width: 54px;
  margin-right: 0;
  margin-left: auto;
}

@media (max-width: 768px) {  
  .root{
    background-color: #BDD0D7;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .facultyLogo{
    width: 18px;
  }

}