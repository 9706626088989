.coursesIntro{
  --desktopActionIcons: 25px;
  padding-bottom: 30px;
}

.intro{
  margin-bottom: 70px;
}

.headerWrapper{
  min-height: 80px;
  border-bottom: 1px solid var(--grey-custom);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.headerWrapperOpen{
  composes: headerWrapper;
  background-color: #F6F6F6;
  border-bottom: none;
}

.headerWrapperClose{
  composes: headerWrapper;
  background-color: white;
}

.bodyWrapper{
  border-bottom: 1px solid var(--grey-custom);
  margin-left: auto;
  margin-right: auto;
}

.header{
}

.header p{
  margin: 0;
}

.bodyDesktop{
  padding-left: var(--desktopActionIcons);
}

.bodyMobile, 
.bodyDesktop{
  color: var(--grey-custom);
}

@media (max-width: 768px) {  
  .intro{
    margin-bottom: 50px;
  } 
}