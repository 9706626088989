.text {
  margin: 0;
  font-weight: 900;
  padding-right: 10px;
}

.actionItem:hover{
  cursor: pointer;
  background-color: #f8f9fa;
}

.activeItem {
  background-color: #e8e9ea !important;
}