.picture {
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  margin: auto;
}
