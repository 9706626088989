.wrapper{
  /* margin: 2px; */
  position: relative;
  height: 200px;
}

.wrapper img{
  width: 100%;
}

.pillImage{
  background-size: cover;
  background-position: center;
}

.pillOverlay{
  position: absolute;
  top: 0; 
  left: 0;
  color:white;
  display: flex;
  width: 100%;
  height: 100%;
}

.pillOverlay h2{
  text-shadow: 0 0 10px black;
}

.playWrapper{
  margin: auto;
  width: 70px;
}

.textWrapper{
  margin-top: auto;
  margin-left: 20px;
}
