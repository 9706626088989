.more{
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  margin: auto;
  background-color: #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.more p{
  margin: 0;
  padding: 0;
  color: #25248E;
  font-weight: 900;
  font-family: 'PT Serif';
  font-size: 16px;
}

.viewAll{
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}

.viewAll p{
  margin: 0; 
  padding-left: 10px;
}

.visible{
  opacity: 1;
  transition: opacity 0.2s linear !important;
}

.hidden{
  opacity: 0;
}