.facultyExtra .slick-list{
  /* overflow: unset !important; */
}

.pillModal .modal-header{
  border-bottom: 0;
}


/* -- https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints -- */

@media (min-width: 576px){
  .pills .slick-track .slick-slide{
    padding-left: 30px;
    padding-right: 30px;
  }
  
  /* Use same dimension as above rule */
  .negativeMargin{
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media (min-width: 768px){
  .pills .slick-next{
    right: 0;
  }
  .pills .slick-prev{
    left: 0;
  }
}

@media (min-width: 992px){
  .pillModal.modal-dialog {
    max-width: 800px !important;
  }

  .pillModal .modal-body{
    min-height: 400px;
  }
}