.top {
  background-image: linear-gradient(to right, #8C12CF, #3F95E9);
  padding-top: 70px;
  padding-bottom: 70px;
  color: white;
  --cover-height: 275px;
}

.cover {
  height: var(--cover-height);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
}

.coverDesk {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: var(--facultyCoverHeight);
}

.description {
  font-size: 16px;
}

.contentWrappeer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentDesktop{
  padding-left: 30px;
}

.teacherName{
  font-size: 16px;
  font-weight: 900;
}

.teacherPosition{
  font-size: 16px;
}

.nameWrapper{
  margin-bottom: 18px;
}

.categoriesWrapper {
  margin-bottom: 30px;
}

.nameWrapper h2{
  margin: 0;
}

.statsBadgeMobile{
  display: flex;
  justify-content: space-around;
}

.desktopIcons{
  color: #25248E;
}

.topExtra, .topCourses{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topCtaDesktop, .topCtaMobile{
  text-align: center;
}

.topExtra{
  border-right: 2px solid rgba(255,255,255,0.3);
  border-left: 2px solid rgba(255,255,255,0.3);
}

.topCourses h5, 
.topExtra h5, 
.topCtaDesktop h5{
  margin-bottom: 24px;
}

.mobileDivider, 
.desktopDivider{
  height: 2px;
  border: 1px solid rgba(255,255,255,0.3);
  margin-top: 40px;
}

.desktopDivider{
  margin-bottom: 20px;
  margin-top: 20px;
}

.topSpacer{
  height: 35px;
  margin-top: 44px;
  margin-bottom: 18px;
}

.topCoursesImg{
  height: 100%;
}

.logo{
  width: 125px;
  margin-left: auto;
  margin-right: auto;
}

.facultyPrice{
  margin-top: 15px;
  padding: 0 40px 0 40px;
}

.desktopLogo{
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.facultyPrice{
  padding: 0;
}

.landingLink{
  
}

@media (max-width: 992px) {  
  .top{
    --gradient-top: #8C12CF;
    --gradient-bottom: rgba(37,36,142,0);
    background: linear-gradient( var(--gradient-top), var(--gradient-top) var(--cover-height) ,#3F95E9);
  }

  .cover {
    width: inherit;
    border-radius: 0
  }

  .cover::before{
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; 
    height: 100%;
    background: linear-gradient(0deg, var(--gradient-top) 0%, var(--gradient-bottom) 110%);
  }

  .contentDesktop{
    padding-left: 0;
  }

  .nameRow{
    order: 1;
  }
  
  .categoriesRow{
    order: 3;
  }
  
  .mainContent{
    order: 2;
  }
  
  .nameWrapper h2{
    text-align: center
  }
  
  .top{
    padding-top: 0;
  }
  
  .description{
    text-align: center;
  }
  
  .categoriesWrapper{
    text-align: center;
  }
  
  .topExtra{
    border: 0;
  }

  .logo{
    width: 60px;
    margin-top: -40px;
  }

  .topCtaMobile{
    margin-top: 26px; 
  }

  .ctaMobileWrapper{
    padding: 20px 0 20px 0;
  }

  .ctaMobileWrapper button{
    margin-top: 35px;
  }

  .ctaMobileWrapper .facultyPrice{
    margin-top: 15px;
  }

  .ctaMobileWrapper{
    border: 1px solid #FFFFFF;
    border-radius: 8px;
  }

  .buyButton{
    padding: 18px 14px !important;
  }

  .facultyPrice{
    padding: 0 20px 0 20px;
  }
}