.articleModal .modal-header{
  border-bottom: 0;
  padding-bottom: 0;
}

.articleModal .modal-body{
  padding-top: 0;
  padding: 0 50px 15px 50px;
}

.articleSlider .slick-arrow.slick-next{
  right: -21px
}

.articleSlider .slick-arrow.slick-prev{
  left: -24px
}

.newsIntro .long_arrow_left:hover .fillTag,
.newsIntro .long_arrow_right:hover .fillTag{
  fill: #25248E;
}

.newsIntro{
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) { 
  .articleSlider{
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .articleRoot.shadow{
    box-shadow: 0 .3rem 0.6rem rgba(0,0,0,.15) !important;
  }
  .articleModal .modal-body{
    padding-top: 0;
    padding-left: 50px;
    padding: 0 15px 15px 15px;
  }
}