.fixed{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixedBlue{
  composes: fixed;
  background-color: #172E88;
}

.fixedBlue a, 
.fixedBlue a:hover{
  color: white !important;
}

.fixedWhite{
  composes: fixed;
  background-color: white;
}

.fixedWhite a, 
.fixedWhite a:hover{
  color: black !important;
}

.fixedHeightReplace{
  flex-shrink: 0;
}

.brandImage{
  width: 40px;
}

.ctaEntraGratis{
  margin-left: 47px;
}

.textActionLink_navLink {
  cursor: pointer;
  font-family: 'Lato';
  font-size: 12px;
  margin: auto;
  font-weight: 600;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.textNavLink_navLink{
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 600;
}

.textActionLink_root {
  display: flex;
}

.logoWrapper{
  width: 40px;
  height: 27px;
  display: block;
}

.smart{
  composes: fixed;
  position: absolute;
  background-color: rgba(0,0,0,0);
  color:white;
}

.smartFixed{
  composes: fixedWhite;
  top: 0;
  transition-property: top;
  transition-duration: 0.5s;
}

.smartFixedHide{
  composes: smartFixed;
  top: -60px;
}

.userName a,
.userName a:hover{
  color: white;
}

@media (max-width: 320px) {  
  .textNavLink_navLink{
    padding: 0 !important;
  }
}