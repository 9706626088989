.aboutWrapper{
  padding-top: 110px;
  background-color: white;
  padding-bottom: 90px;
}

.aboutDescription{
  color: var(--grey-custom);
}

.statsRoot{
  flex-direction: column;
}

.statsText{
  color: var(--grey-custom);
  font-size: 16px;
}

.statsValue{
  font-weight: 900;
  color: black;
}

.faq{
  padding-bottom: 230px;
  padding-top: 115px;
}

.mobileCta{
  position: fixed;
  bottom: 0;
  z-index: 666;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  box-shadow: 0px -1px 5px grey;
}

@media (max-width: 768px) {  
  .aboutWrapper{
    padding-top: 60px;
    margin-bottom: 15px;
  }

  .statsRoot{
    flex-direction: row;
  } 

  .statsBox{
    width: 50%;
  }

  .stats{
    margin-top: 20px;
  }
}