/* TO USE sass to override Bootstrap defaults
change this file extension to .scss and import name in index.js
and uncomment line below, 
than remove 'import 'bootstrap/dist/css/bootstrap.min.css';' from index.scss */

/* override variable here */

/* $container-max-widths: (
  xl: 1440px
); */

/* @import "node_modules/bootstrap/scss/bootstrap"; */

html{
  /* fix for jumping scrollbar issue */
  overflow-y: scroll;;
}

body {
  margin: 0;
  /* add !important to prevent bootstrap 'padding-right 15px' on modal open in desktop.. */
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --grey-custom: #676767;
  --red-custom: #FF3366;
  --facultyCoverHeight: 400px;
  --main-background: #F1F1F2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.noDecoration, 
.noDecoration:hover, 
.noDecoration:visited, 
.noDecoration:focus, 
.noDecoration:active{
  text-decoration: none;
  color:inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'PT Serif';
  font-weight: 700;
}

p {
  font-family: 'Lato';
  font-weight: 400;
}

p{
  font-size: 14px;
}

p.big{
  font-size: 16px;
}

p.label{
  font-size: 12px;
  text-transform: uppercase;
}

p.extra{
  font-size: 10px;
  letter-spacing: 2.1px;
  text-transform: uppercase;
}

/* ?? */
h5{
  font-size: 18px;
}

h4{
  font-size: 21px;
}

h3{
  font-size: 28px;
}

h2{
  font-size: 38px;
}

h1{
  font-size: 50px;
}

.btn:focus {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}

.text-gray{
  color: #94A5C1;
}

.text-red-1{
  color: #FF3366;
}

.text-blue{
  color: #25248E;
}

.c-pointer{
  cursor: pointer;
}

@media (min-width: 1200px) { 
  p{
    font-size: 14px;
  }

  p.big{
    font-size: 16px;
  }

  p.label{
    font-size: 12px;
    text-transform: uppercase;
  }

  p.extra{
    font-size: 10px;
    letter-spacing: 2.1px;
    text-transform: uppercase;
  }

  h5{
    font-size: 21px;
  }

  h4{
    font-size: 28px;
  }

  h3{
    font-size: 38px;
  }

  h2{
    font-size: 50px;
  }

  h1{
    font-size: 66px;
  }
}
