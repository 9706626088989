.root{
  min-height: 365px;
  padding-bottom: 20px;
  cursor: pointer;
}

.pillTitle{
  min-height: 85px;
  justify-content: space-between;
}

.vimeoPreview{
  margin-top: 15px;
}

.pillDuration{
  width: 100%;
  text-align: left;
}

.pillDuration p{
  margin-bottom: 0;
  margin-top: 5px;
}