.categories {
  margin-bottom: 1rem;
}

.statBox {
  margin-bottom: 0.75rem;
}

.courseWrapper{
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 75px;
}

.courseWrapper.coursesList{
  justify-content: initial;
}

.coursesIntro{
  padding-top: 74px;
  padding-bottom: 150px;
}

.logoWrapper {
  height: 46px;
  width: 65px;
}

.introTitle{
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

.introTitle h2{
  font-family: 'Lato';
  font-size: 49px;
  font-weight: 300;
  color: #9F9F9F;
  margin-bottom: 15px;
  margin-left: 10px;
}

.introDescription{
  color: #424B5A;
  letter-spacing : 0.96px;
  margin-left: auto;
  margin-right: auto;
}

.grayColor{
  color: var(--grey-custom);
}

.audiences{
  composes: grayColor;
  margin-bottom: 0;
}

.concepts{
  composes: grayColor;
  display: inline;
}

.conceptsCol ul{
  padding-inline-start: 0;
}

.conceptsCol li{
  list-style: none;
  margin-bottom: 0.5rem;
}

.conceptsCol li span{
  margin-right: 10px;
}

.teachersIntroDescription{
  color: var(--grey-custom);
  font-size: 14px !important;
  width: 50%;
  margin-bottom: 0;
}

.coursesIntroDescription{
  width: 40%;
}

.faq{
  padding-bottom: 230px !important;
  padding-top: 115px;
}

@media (max-width: 768px) {  
  .preview{
    margin: auto;
  }

  .coursesIntro{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .introTitle{
    margin-bottom: 0;
  }

  .reasonCol, 
  .audienceCol, 
  .conceptsCol{
    margin-top: 50px;
  }

  .introDescription{
    width: 100% !important;
  }

  .faq{
    padding-bottom: 180px !important;
  } 
}