.appWrapper{
  /* fill page height, set <main> style to "flex: 1" */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.noScroll{
  height: 100vh;
  min-height: 0;
}

@media (max-width: 768px){
  .noScroll{
    min-height: 100vh;
    height: auto;
  }
}