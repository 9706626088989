.btn.bluButton{
  background-color: #25248E;
  border-color: #25248E;
  height: 40px;
  border-radius: 20px;
  font-size: 12px;
  font-family: Lato;
}

.btn-secondary.bluButton:focus,
.btn-secondary.bluButton:active,
.btn-secondary.bluButton:hover{
  background-color: #25248E !important ;
  border-color: #25248E !important;
}