.countDownWrap{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: white;
}

.countdownValues p{
  font-weight: 300;
}

@media (max-width: 768px) {  
  .countdownValues{
    padding: 0 40px 0 40px;
  }

  .countdownValues p{
    font-size: 12px;
  }
}

@media (max-width: 375px) {  
  .countdownValues{
    padding: 0 5px 0 5px;
  }
  
  .countdownValues p{
    font-size: 11px;
  }

  .countdownValues h2{
    font-size: 38px;
  }
}

@media (max-width: 320px) {  
  .countdownValues{
    padding: 0 5px 0 5px;
  }
  
  .countdownValues p{
    font-size: 10px;
  }

  .countdownValues h2{
    font-size: 30px;
  }
}