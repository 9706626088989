.headerRow {
  padding-bottom: 100px;
  position: relative;
  height: 100%;
  background-image: url(../../assets/images/home.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.headerRow::before{
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; 
  height: 100%;
  background-color: #172E88;
	opacity: .9; 
}

.headerCol {
  position: relative;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  color: white;
  text-align: center;
}

.logoBox {
  text-align: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.logo {
  width: 180px;
}

.p1 {
  font-size: 16px;
  letter-spacing: 2.1px;
  line-height: 24px;
}

.ctaWrapper {
  margin-top: 80px;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.zig {
  composes: section;
  background-color: #F6F6F6;
}

.zag {
  composes: section;
  background-color: #FFFFFF;
}

.facultyList {
  margin-top: -180px;
}

.path {

}

.pathItem {
  text-align: center;
}

.pathFont {
  font-family: 'PT Serif';
  font-weight: bold;
  font-size: 21px;
}

.pathImage {
  border-radius: 50%;
  max-width: 160px;
  max-height: 160px;
}

.pathNumber {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  background-color: #25248E;
  color: white;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.pathTitle {
  color: #161616;
}

.pathBody {
  color: #424B5A;
  font-family: 'Lato';
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.79px;
  opacity: 0.65;
}

.upsterBody {
	color: #424B5A;
	font-family: 'Lato';
	font-size: 14px;
	line-height: 21px;
}

.upperIntro {
  text-align: center;
}

.upperImage {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 7px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.upperName p,
.upperPosition p{
  margin: 0;
}

.upperPosition {
  color: var(--grey-custom);
}

.upperReview {
  margin-top: 1.5rem;
  color: var(--grey-custom);
}

.testimonial {
  border: solid 1px #C2D1D9;
  border-radius: 3px;
  padding: 50px;
}
