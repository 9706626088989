.containerFluid{
  --back-color: #F8F8F8;
  background-color: var(--back-color);
}
.container{
  padding-top: 46px;
  padding-bottom: 40px;
  background-color: var(--back-color);
}

.articleRoot{
  cursor: pointer;
  box-shadow: 0 .1rem 0.1rem rgba(0,0,0,.15)!important
}

.imageContainer{
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 15px;
}

.audioBadge{
  position: absolute;
  top: 128px;
  left: 30px;
}

.audioBadge.audioDisabled{
  display: none;
}

.articlePublishedWrapper{
  margin-top: 25px;
}

.articlePublished{
  font-size: 12px;
  font-weight: bold;
  color: var(--red-custom);
  margin: 0;
}

.articleTitle{
  margin-top: 25px;
  min-height: 50px;
  height: 50px;
}

.articleTitle p{
  font-family: 'PT Serif';
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.articleCta{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
}

.articleCta p{
  margin: 0;
  color: #25248E;
  font-weight: 900;
}

.newsIntro{
  padding-top: 26px;
  height: 356px;
}

.newsIntro p{
  color: var(--grey-custom);
  margin: 60px 0 0 0;
}

.sliderItem{
  padding: 10px;
}

.sliderArrowLeft.disabled,
.sliderArrowRight.disabled{
  display: none;
}

.sliderArrowWrap{
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) { 
  .container{
    padding-top: 40px;
  } 

  .newsIntro{
    height: initial;
  }

  .newsIntro p{
    margin-top: 10px
  }
}