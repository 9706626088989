.root{
  cursor: pointer;
}

.root.disabled{
  cursor: initial;
} 


.root input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  /* cursor: pointer; */
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  background: rgba(255, 255, 255, 0.98)
  /* border-radius: 1.3px; */
  /* border: 0.2px solid #010101; */
}