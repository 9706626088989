.wrapperRoot{
  width: 100%;
  height: 0;
  padding-top: 60%;
  position: relative;
}


.customPlayerWrapper{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.clickableArea{
  width: 100%;
  height: 80%;
}

.dataWrapper{
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}

.progressTime{
  width: 80px;
}

.progressTime p{
  margin: 0;
  color: white;
  margin-left: 10px;
}

.progressWrapper{
  width: 100%;
}

.progress{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.controlButtonWrap{
  position: absolute;
  width: 50px;
  height: 30px;
  top: calc(50% - 15px);
  left: calc(50% - 25px);
}
