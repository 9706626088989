.logo {
  width: 70px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.wrapper {
  border-top: 1px	solid #C2D1D9;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.content {
  text-align: center;
}

.tcwrap{
  margin-bottom: 3px;
}

.termsconditions{
  font-size: 11px;
  line-height: 18px;
  font-weight: bold;
  color: #59636D;
  border: 1px solid #D2D3D3;
  border-radius: 3px;
  padding: 4px;
}

.termsconditions:hover{
  text-decoration: none;
  color: #333;
}