.btn.customActionButton{
  font-family: 'Lato';
}

.btn.customActionButton.modeDefault{
  font-size: 15px;
  letter-spacing: 1.4px;
  line-height: 18px;
  border-radius: 30px;
  padding: 20px 25px;
  box-shadow: 0 11px 14px 0 rgba(22,24,78,0.5);
  font-weight: 900;
}

.btn.customActionButton.modeDefault:focus{
  box-shadow: 0 11px 14px 0 rgba(22,24,78,0.5) !important;
}

.btn.customActionButton.modeSmall{
  font-size: 12px;
  border-radius: 20px;
  line-height: 15px;
  padding: 12px 20px;
  box-shadow:  0 5px 8px 0 rgba(22,24,78,0.5);
}

.btn.customActionButton.modeSmall:focus{
  box-shadow:  0 5px 8px 0 rgba(22,24,78,0.5) !important;
}

.btn.customActionButton.no-shadow{
  box-shadow: none;
}

.btn.customActionButton.no-shadow:focus{
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
}