.modal{
  max-width: 420px !important;
}

.overlay{
  position: absolute;
  background-color: rgba(255,255,255,0.7);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blur{
  filter: blur(1px);
}