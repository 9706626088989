.playRoot{
  position: relative;
}

.playOverlay{
  position: absolute;
  top: 0;
  left: 0;
}

.svgCircle{
  transition: all 0.7s ease-in-out;
}

.playButton{
  background-image: url(../../../../assets/images/play.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.playButton.complete{
  background-image: url(../../../../assets/images/play_complete.png);
}